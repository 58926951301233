function constructPartnerMap(socialLinks, isTest) {
	const partnerArray = [];
	socialLinks.forEach(function (socialLink) {
		const originalHref = socialLink.getAttribute("href");
		const partnerSlug = originalHref.split("/").pop();

		if (!partnerArray.includes(partnerSlug)) {
			partnerArray.push(partnerSlug);
		}
	});

	const partnerMap = Object.fromEntries(
		partnerArray.map(function (partner, idx) {
			return [partner, { rank: idx + 1 }];
		}),
	);

	if (isTest) {
		return setHrefs(socialLinks, partnerMap, isTest);
	} else setHrefs(socialLinks, partnerMap);
}

function setHrefs(socialLinks, partnerMap, isTest) {
	socialLinks.forEach(function (socialLink) {
		const originalHref = socialLink.getAttribute("href");
		const partnerSlug = originalHref.split("/").pop();
		const partnerRank = partnerMap[partnerSlug].rank;

		/* Set the count for amount of unique partner links */
		if (!partnerMap[partnerSlug].count) {
			partnerMap[partnerSlug].count = 1;
			socialLink.setAttribute(
				"href",
				originalHref + "?" + "s_rank=" + partnerRank,
			);
		} else {
			partnerMap[partnerSlug].count = partnerMap[partnerSlug].count + 1;
			socialLink.setAttribute(
				"href",
				originalHref +
					"?" +
					"s_rank=" +
					partnerRank +
					"&s_sub_rank=" +
					partnerMap[partnerSlug].count,
			);
		}
	});

	if (isTest) {
		return Array.from(socialLinks).toString();
	}
}

const addVisitLinkRanks = (socialSection) => {
	const isTest = !!socialSection;
	const socialContentSection = socialSection
		? socialSection.querySelector("#socialContent")
		: document.querySelector("#socialContent");
	if (socialContentSection) {
		const socialLinks = Array.prototype.slice.call(
			socialContentSection.querySelectorAll('a[href*="/visit/"]'),
			0,
		);
		if (isTest) {
			return constructPartnerMap(socialLinks, isTest);
		} else {
			constructPartnerMap(socialLinks);
		}
	}
};

export default addVisitLinkRanks;
