/* The logic in this file is used to apply classes for styling purposes on specific elements. Notably HTML created from markdown */

const addMarkdownClasses = () => {
	/* Used on top services page */
	function topServicesClasses() {
		const contentBlock = document.querySelector(".content-block");
		if (contentBlock) {
			const aElementsOfContentBlock = Array.prototype.slice.call(
				contentBlock.querySelectorAll("p > a"),
				0,
			);
			aElementsOfContentBlock.forEach(function (element) {
				if (element.childElementCount === 0) {
					element.classList.add("contentBlock-a_blueDashedBottomBorder");
				}
			});
		}
	}
	topServicesClasses();

	/* Used on single article page */
	function singleArticleClasses() {
		const articleContainer = document.querySelector(".articleContainer");
		if (articleContainer) {
			const imgElementsOfArticleContainer = Array.prototype.slice.call(
				articleContainer.querySelectorAll("p > img"),
				0,
			);
			imgElementsOfArticleContainer.forEach(function (element) {
				element.classList.add("img-responsive");
			});
		}
	}
	singleArticleClasses();

	/* Used on individual review page */
	function individualReviewClasses() {
		const productDesc = document.querySelector(".product_desc");
		if (productDesc) {
			const imgElementsOfProductDesc = Array.prototype.slice.call(
				productDesc.querySelectorAll("p > img"),
				0,
			);
			imgElementsOfProductDesc.forEach(function (element) {
				element.classList.add("img-responsive");
			});
		}
	}
	individualReviewClasses();

	/* Used on individual review page */
	function socialContentClasses() {
		const socialContentSection = document.querySelector("#socialContent");
		if (socialContentSection) {
			const links = Array.prototype.slice.call(
				socialContentSection.querySelectorAll("a"),
				0,
			);
			links.forEach(function (element) {
				element.classList.add(
					"tw-font-semibold",
					"tw-underline",
					"tw-text-tcr-social-link",
				);
			});

			const lists = Array.prototype.slice.call(
				socialContentSection.querySelectorAll("ul li"),
				0,
			);
			lists.forEach(function (element) {
				element.classList.add(
					"tw-text-xl",
					"tw-text-black",
					"tw-bg-redCheck",
					"tw-leading-snug",
					"tw-mb-10px",
				);
			});

			const headers = Array.prototype.slice.call(
				socialContentSection.querySelectorAll("h1, h2, h3, h4, h5, h6"),
				0,
			);
			headers.forEach(function (element) {
				element.classList.add("tw-text-black");
			});

			const h2s = Array.prototype.slice.call(
				socialContentSection.querySelectorAll("h2"),
				0,
			);
			h2s.forEach(function (element) {
				element.classList.add("tw-text-2rem");
			});

			const pElements = Array.prototype.slice.call(
				document.querySelector("#socialContentWrapper").querySelectorAll("p"),
				0,
			);
			pElements.forEach(function (element) {
				element.classList.add("tw-mb-1.3");
			});
		}
	}
	socialContentClasses();
};

export default addMarkdownClasses;
