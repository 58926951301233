/* This file is used across the entirety of the site
 *
 * If you need to make any changes to a function or add a function/logic, for organization purposes,
 * please indicate what the logic does and what blades it applies to.
 *
 * */

/* eslint-disable no-magic-numbers */
import { intializeHeadroom } from "../../../bladejs/initialize-headroom";
import { createWaitUntilJQuery } from "../../../bladejs/wait-until-jquery";
import addMarkdownClasses from "./dynamically-add-markdown-classes";
import addVisitLinkRanks from "./socialLinks";

document.addEventListener(
	"DOMContentLoaded",
	createWaitUntilJQuery(() => {
		// Used sitewide for hiding/displaying header on scroll
		intializeHeadroom();

		/* Used sitewide to scroll to the top of the page */
		function backToTop() {
			$("#backToTop").on("click", function () {
				$("html, body").animate({ scrollTop: 0 }, "slow");
			});
		}
		backToTop();

		/* Used sitewide for menu options in header */
		function megaMenu() {
			$(".menu-arrow").on("click", function () {
				$(this).parent(".menu-holder").siblings(".menu-wrapper").slideToggle();
			});
			$(".menu-arrow").on("click", function () {
				$(this)
					.parent(".menu-level1")
					.parent(".navbar-title")
					.siblings(".menu-list-wrapper")
					.slideToggle();
			});
		}
		megaMenu();

		/* Used to generate filled and unfilled stars in individual-service-review.blade.php */
		function userReviewStars() {
			$(".comment-div, .user-review")
				.find(".user-rating")
				.each(function () {
					var ratingValue = $(this).text();
					var remainValue = 5 - ratingValue;
					for (var i = 0; i < ratingValue; i++) {
						$(this)
							.siblings(".userReviewStars-span_wrapper")
							.append('<span class="ratingStar_filled"></span>');
					}

					for (var j = 0; j < remainValue; j++) {
						$(this)
							.siblings(".userReviewStars-span_wrapper")
							.append('<span class="ratingStar_empty"></span>');
					}
				});
		}
		userReviewStars();

		/* Used for equalizing row height in individual-service-review.blade.php */
		function matchHeight() {
			$(".parent").each(function () {
				$(this).find(".child").matchHeight({
					byRow: true,
				});
			});
		}
		matchHeight();

		/* Used to apply fixed position in individual-service-review.blade.php */
		function fixedHeadSpot() {
			$(window).on("scroll", function () {
				var fixed = $("#getFixed");
				if ($(window).scrollTop() > 114) {
					fixed.css({
						position: "fixed",
						top: "55px",
						"z-index": "9",
						width: "100%",
					});
				} else {
					fixed.css({
						position: "relative",
						top: "auto",
					});
				}
			});
		}
		fixedHeadSpot();

		/* Used sitewide to close advertiser disclosure */
		$(".close-icon").on("click", function () {
			$(".advertiser-disclosure-dropdown").removeClass("open");
		});

		// controls the display of the advertiser disclosure on clone sites
		$(".advertiserDisclosureToggle").on("click", function () {
			$(".advertiserDisclosureWindow").toggleClass(["tw-hidden", "tw-flex"]);
		});

		/* Used in single-article.blade.php as CSS doesn't provide a parent selector */
		$(".landing-page .left-sidebar p a img")
			.parent()
			.css("border-bottom", "none");

		/* Logic used for scrolling and anchors in individual-service-review.blade.php */
		function reviewScroll() {
			$('a[href^="#review"], a[href="#addReview"]').on("click", function (e) {
				e.preventDefault();
				var destination = $(this).attr("href");

				if (destination === "#addReview") {
					$("#user-review-name").focus();
				} else {
					$("html, body").animate(
						{
							scrollTop: $(destination).offset().top - $(window).height() / 3,
						},
						"slow",
					);
				}
			});
		}
		reviewScroll();

		function mobileMenuScroll() {
			/* If viewport is wide enough to accommodate uncollapsed nav menu, then enable
							menu scrolling for double category personal/business loans */
			function checkDimensions() {
				if ($(window).width() > 767 && $(window).height() < 560) {
					return true;
				} else {
					return false;
				}
			}

			// Check on first load
			if (checkDimensions()) {
				$("#sub-menu-scroll").addClass("sub-menu-scroll");
			}

			// Also check when window is resized
			$(window).on("resize", function () {
				if (checkDimensions()) {
					$("#sub-menu-scroll").addClass("sub-menu-scroll");
				} else {
					$("#sub-menu-scroll").removeClass("sub-menu-scroll");
				}
			});

			/* Upon clicking hamburger to expand menu in mobile view, render the nav menu as full front page to allow scrolling
			 * and disable main page scroll*/
			$("#bs-example-navbar-collapse-1").on("shown.bs.collapse", function () {
				$("html").toggleClass("noScroll");
				$("#bs-example-navbar-collapse-1").css("height", "95vh");
			});

			/* Return the focus back on the main page after closing mobile nav menu */
			$("#bs-example-navbar-collapse-1").on("hide.bs.collapse", function () {
				$("html").toggleClass("noScroll");
			});
		}
		mobileMenuScroll();

		function toggleSingleBenefits() {
			$(".individualServiceBlock-toggleBenefits").on("click", function () {
				$(this).css("display", "none");
				$(".individualServiceBlock-hideBenefitsContainer").css(
					"display",
					"inline",
				);
			});
		}
		toggleSingleBenefits();

		function verticalTableRenderer() {
			function checkDimensions() {
				return $(window).width() > 700;
			}

			$(window).on("load resize", function () {
				if (checkDimensions()) {
					$(".ranking-table_mobile").css("display", "none");
					$(".ranking-table").css("display", "block");
				} else {
					$(".ranking-table_mobile").css("display", "block");
					$(".ranking-table").css("display", "none");
				}
			});
		}
		verticalTableRenderer();

		addVisitLinkRanks();
		addMarkdownClasses();
	}),
);
