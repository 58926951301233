// eslint-disable-next-line arrow-body-style
export const createWaitUntilJQuery = (callback) => {
	return function waitUntilJQuery() {
		if (window.$) {
			callback();
		} else {
			// eslint-disable-next-line no-magic-numbers
			window.setTimeout(waitUntilJQuery, 50);
		}
	};
};
